import Vue from 'vue';
import VueRouter from 'vue-router';
import { get } from 'lodash-es';



Vue.use(VueRouter);

const routes = [ 
  {
    path: '/user/my',
    meta: {
      title: '个人中心'
    },
    component: () => import('../views/user/my.vue')
  },
  {
    path: '/user/info',
    meta: {
      title: '填写信息'
    },
    component: () => import('../views/user/info.vue')
  },
  {
    path: '/user/integral_buy',
    meta: {
      title: '我的积分'
    },
    component: () => import('../views/user/integral_buy.vue')
  },
  {
    path: '/user/integral_query',
    meta: {
      title: '积分查询'
    },
    component: () => import('../views/user/integral_query.vue')
  },
  {
    path: '/admin/info',
    meta: {
      title: '填写信息'
    },
    component: () => import('../views/admin/info.vue')
  },
  {
    path: '/admin/tel',
    meta: {
      title: '验证手机号'
    },
    component: () => import('../views/admin/tel.vue')
  },
  {
    path: '/admin/self_help_reg',
    meta: {
      title: '自助注册'
    },
    component: () => import('../views/admin/self_help_reg.vue')
  },
  {
    path: '/admin/self_help_reg_1',
    meta: {
      title: '测试注册'
    },
    component: () => import('../views/admin/self_help_reg_1.vue')
  },
  {
    path: '/admin/admin_index',
    meta: {
      title: '管理主页'
    },
    component: () => import('../views/admin/admin_index.vue')
  },
  {
    path: '/car/car_login',
    meta: {
      title: '登陆'
    },
    component: () => import('../views/car/car_login.vue')
  },
  {
    path: '/car/car_list',
    meta: {
      title: '车辆管理'
    },
    component: () => import('../views/car/car_list.vue')
  },
  {
    path: '/boss/bar_add',
    meta: {
      title: '添加吧台信息'
    },
    component: () => import('../views/boss/bar_add.vue')
  },
  {
    path: '/boss/bar_detail',
    meta: {
      title: '吧台信息详情'
    },
    component: () => import('../views/boss/bar_detail.vue')
  },
  {
    path: '/boss/bar_list',
    meta: {
      title: '吧台信息管理'
    },
    component: () => import('../views/boss/bar_list.vue')
  },
  {
    path: '/boss/bind_add',
    meta: {
      title: '添加绑定'
    },
    component: () => import('../views/boss/bind_add.vue')
  },
  {
    path: '/boss/bind_list',
    meta: {
      title: '绑定管理'
    },
    component: () => import('../views/boss/bind_list.vue')
  },
  {
    path: '/boss/region_add',
    meta: {
      title: '添加区域信息'
    },
    component: () => import('../views/boss/region_add.vue')
  },
  {
    path: '/boss/region_edit',
    meta: {
      title: '修改区域信息'
    },
    component: () => import('../views/boss/region_edit.vue')
  },
  {
    path: '/boss/region_list',
    meta: {
      title: '区域信息管理'
    },
    component: () => import('../views/boss/region_list.vue')
  },
  {
    path: '/boss/room_add',
    meta: {
      title: '添加房间信息'
    },
    component: () => import('../views/boss/room_add.vue')
  },
  {
    path: '/boss/room_add_bak',
    meta: {
      title: '添加房间信息'
    },
    component: () => import('../views/boss/room_add_bak.vue')
  },
  {
    path: '/boss/room_edit',
    meta: {
      title: '修改房间信息'
    },
    component: () => import('../views/boss/room_edit.vue')
  },
  {
    path: '/boss/room_list',
    meta: {
      title: '房间信息管理'
    },
    component: () => import('../views/boss/room_list.vue')
  },
  {
    path: '/boss/room_ewm_detail',
    meta: {
      title: '查看房间二维码'
    },
    component: () => import('../views/boss/room_ewm_detail.vue')
  },
  {
    path: '/boss/room_pass',
    meta: {
      title: '房间密码管理'
    },
    component: () => import('../views/boss/room_pass.vue')
  },
  {
    path: '/boss/room_state',
    meta: {
      title: '房间状态信息'
    },
    component: () => import('../views/boss/room_state.vue')
  },
  {
    path: '/boss/room_details',
    meta: {
      title: '房间详情信息'
    },
    component: () => import('../views/boss/room_details.vue')
  },
  {
    path: '/boss/room_census',
    meta: {
      title: '统计信息'
    },
    component: () => import('../views/boss/room_census.vue')
  },  
  {
    path: '/boss/place_switch',
    meta: {
      title: '切换场所'
    },
    component: () => import('../views/boss/place_switch.vue')
  },  
  {
    path: '/boss/check_in_query',
    meta: {
      title: '信息查询'
    },
    component: () => import('../views/boss/check_in_query.vue')
  }, 
  {
    path: '/boss/check_in_query_details',
    meta: {
      title: '入住详情'
    },
    component: () => import('../views/boss/check_in_query_details.vue')
  }, 
  {
    path: '/boss/modify_info',
    meta: {
      title: '场所信息'
    },
    component: () => import('../views/boss/modify_info.vue')
  },
  {
    path: '/boss/modify_map',
    meta: {
      title: 'GPS地图'
    },
    component: () => import('../views/boss/modify_map.vue')
  },
  {
    path: '/boss/list_bak',
    meta: {
      title: '列表测试'
    },
    component: () => import('../views/boss/list_bak.vue')
  },
  {
    path: '/boss/room_set_computer',
    meta: {
      title: '房间电脑数设置'
    },
    component: () => import('../views/boss/room_set_computer.vue')
  },
  {
    path: '/boss/room_set_ip_list',
    meta: {
      title: '房间IP列表'
    },
    component: () => import('../views/boss/room_set_ip_list.vue')
  },
  {
    path: '/boss/room_set_ip_add',
    meta: {
      title: '房间IP添加'
    },
    component: () => import('../views/boss/room_set_ip_add.vue')
  },
  {
    path: '/boss/room_set_ip_edit',
    meta: {
      title: '房间IP编辑'
    },
    component: () => import('../views/boss/room_set_ip_edit.vue')
  },
  {
    path: '/tourist/travelers_list',
    meta: {
      title: '出行人管理'
    },
    component: () => import('../views/tourist/travelers_list.vue')
  },
  {
    path: '/tourist/travelers_query',
    meta: {
      title: '出行记录'
    },
    component: () => import('../views/tourist/travelers_query.vue')
  },
  {
    path: '/tourist/continued',
    meta: {
      title: '续住申请'
    },
    component: () => import('../views/tourist/continued.vue')
  },
  {
    path: '/tourist/travelers_add',
    meta: {
      title: '添加身份信息'
    },
    component: () => import('../views/tourist/travelers_add.vue')
  },
  {
    path: '/tourist/select_peers',
    meta: {
      title: '选择同行人'
    },
    component: () => import('../views/tourist/select_peers.vue')
  },
  {
    path: '/tourist/checkin_travelers_add',
    meta: {
      title: '添加身份信息'
    },
    component: () => import('../views/tourist/checkin_travelers_add.vue')
  },
  {
    path: '/tourist/peers_photo_1',
    meta: {
      title: '同行人近照管理'
    },
    component: () => import('../views/tourist/peers_photo_1.vue')
  },
  {
    path: '/tourist/peers_photo_2',
    meta: {
      title: '确认同行人信息'
    },
    component: () => import('../views/tourist/peers_photo_2.vue')
  },
  {
    path: '/barcounter/check_in',
    meta: {
      title: '办理入住'
    },
    component: () => import('../views/barcounter/check_in.vue')
  },
  {
    path: '/barcounter/check_in_room_state',
    meta: {
      title: '选择入住房间'
    },
    component: () => import('../views/barcounter/check_in_room_state.vue')
  },
  {
    path: '/barcounter/check_in_room_details',
    meta: {
      title: '房间详情'
    },
    component: () => import('../views/barcounter/check_in_room_details.vue')
  },
  {
    path: '/barcounter/check_in_assist_register',
    meta: {
      title: '协助登记'
    },
    component: () => import('../views/barcounter/check_in_assist_register.vue')
  },
  {
    path: '/barcounter/check_in_travelers_details',
    meta: {
      title: '入住人员详情'
    },
    component: () => import('../views/barcounter/check_in_travelers_details.vue')
  },
  {
    path: '/barcounter/check_in_room_list',
    meta: {
      title: '房间列表'
    },
    component: () => import('../views/barcounter/check_in_room_list.vue')
  },
  {
    path: '/barcounter/assist_register',
    meta: {
      title: '协助登记'
    },
    component: () => import('../views/barcounter/assist_register.vue')
  },
  {
    path: '/barcounter/assist_check_in',
    meta: {
      title: '办理入住'
    },
    component: () => import('../views/barcounter/assist_check_in.vue')
  },
  {
    path: '/barcounter/assist_room_state',
    meta: {
      title: '选择入住房间'
    },
    component: () => import('../views/barcounter/assist_room_state.vue')
  },
  {
    path: '/barcounter/assist_room_details',
    meta: {
      title: '房间详情'
    },
    component: () => import('../views/barcounter/assist_room_details.vue')
  },
  {
    path: '/barcounter/assist_register_1',
    meta: {
      title: '协助登记'
    },
    component: () => import('../views/barcounter/assist_register_1.vue')
  },
  {
    path: '/barcounter/assist_travelers_details',
    meta: {
      title: '入住人员详情'
    },
    component: () => import('../views/barcounter/assist_travelers_details.vue')
  },
  {
    path: '/barcounter/assist_room_list',
    meta: {
      title: '房间列表'
    },
    component: () => import('../views/barcounter/assist_room_list.vue')
  },
  {
    path: '/barcounter/check_out',
    meta: {
      title: '办理退房'
    },
    component: () => import('../views/barcounter/check_out.vue')
  },
  {
    path: '/barcounter/check_out_select_people',
    meta: {
      title: '选择退房人'
    },
    component: () => import('../views/barcounter/check_out_select_people.vue')
  },
  {
    path: '/barcounter/check_out_room_details',
    meta: {
      title: '房间详情'
    },
    component: () => import('../views/barcounter/check_out_room_details.vue')
  },
  {
    path: '/barcounter/check_in_query',
    meta: {
      title: '入住查询'
    },
    component: () => import('../views/barcounter/check_in_query.vue')
  },
  {
    path: '/barcounter/check_in_query_details',
    meta: {
      title: '入住详情'
    },
    component: () => import('../views/barcounter/check_in_query_details.vue')
  },
  {
    path: '/barcounter/continuation',
    meta: {
      title: '续住申请'
    },
    component: () => import('../views/barcounter/continuation.vue')
  },
  {
    path: '/barcounter/continuation_list',
    meta: {
      title: '办理续住房间'
    },
    component: () => import('../views/barcounter/continuation_list.vue')
  },
  {
    path: '/barcounter/continuation_add',
    meta: {
      title: '办理续住'
    },
    component: () => import('../views/barcounter/continuation_add.vue')
  },
  {
    path: '/barcounter/continuation_room_details',
    meta: {
      title: '房间详情'
    },
    component: () => import('../views/barcounter/continuation_room_details.vue')
  },
  {
    path: '/barcounter/continuation_travelers_details',
    meta: {
      title: '人员列表'
    },
    component: () => import('../views/barcounter/continuation_travelers_details.vue')
  },
  {
    path: '/barcounter/menu_check_in',
    meta: {
      title: '办理入住'
    },
    component: () => import('../views/barcounter/menu_check_in.vue')
  },
  {
    path: '/barcounter/menu_room_details',
    meta: {
      title: '房间详情'
    },
    component: () => import('../views/barcounter/menu_room_details.vue')
  },
  {
    path: '/barcounter/menu_room_state',
    meta: {
      title: '选择房间'
    },
    component: () => import('../views/barcounter/menu_room_state.vue')
  },
  {
    path: '/barcounter/choose_mode',
    meta: {
      title: '选择密码获取方式'
    },
    component: () => import('../views/barcounter/choose_mode.vue')
  },
  {
    path: '/barcounter/code_mode',
    meta: {
      title: '输入订单'
    },
    component: () => import('../views/barcounter/code_mode.vue')
  },
  {
    path: '/barcounter/adjust_room_state',
    meta: {
      title: '房间列表'
    },
    component: () => import('../views/barcounter/adjust_room_state.vue')
  },
  {
    path: '/barcounter/adjust_room_state_1',
    meta: {
      title: '房间列表'
    },
    component: () => import('../views/barcounter/adjust_room_state_1.vue')
  },
  {
    path: '/barcounter/adjust_room_people',
    meta: {
      title: '调整房间人员'
    },
    component: () => import('../views/barcounter/adjust_room_people.vue')
  },
  {
    path: '/barcounter/adjust_room_details',
    meta: {
      title: '房间详情'
    },
    component: () => import('../views/barcounter/adjust_room_details.vue')
  },
  {
    path: '/barcounter/msgInvalid',
    meta: {
      title: '信息提示'
    },
    component: () => import('../views/barcounter/msgInvalid.vue')
  },
  {
    path: '/barcounter/checkinerror',
    meta: {
      title: '信息提示'
    },
    component: () => import('../views/barcounter/checkinerror.vue')
  },
  {
    path: '/barcounter/faceerror',
    meta: {
      title: '信息提示'
    },
    component: () => import('../views/barcounter/faceerror.vue')
  },
  {
    path: '/barcounter/pass_success',
    meta: {
      title: '信息提示'
    },
    component: () => import('../views/barcounter/pass_success.vue')
  },
  {
    path: '/barcounter/pass_error',
    meta: {
      title: '信息提示'
    },
    component: () => import('../views/barcounter/pass_error.vue')
  },
  {
    path: '/barcounter/test',
    meta: {
      title: '测试'
    },
    component: () => import('../views/barcounter/test.vue')
  },
  {
    path: '/maintain/modify_info',
    meta: {
      title: '场所信息'
    },
    component: () => import('../views/maintain/modify_info.vue')
  },
  {
    path: '/maintain/modify_map',
    meta: {
      title: 'GPS地图'
    },
    component: () => import('../views/maintain/modify_map.vue')
  },
  {
    path: '/maintain/place_list',
    meta: {
      title: '场所列表'
    },
    component: () => import('../views/maintain/place_list.vue')
  },
  {
    path: '/maintain/place_list_wh',
    meta: {
      title: '场所列表'
    },
    component: () => import('../views/maintain/place_list_wh.vue')
  },
  {
    path: '/refueling/ewm_detail',
    meta: {
      title: '查看二维码'
    },
    component: () => import('../views/refueling/ewm_detail.vue')
  },
  {
    path: '/refueling/my',
    meta: {
      title: '个人信息'
    },
    component: () => import('../views/refueling/my.vue')
  },
  {
    path: '/refueling/callback',
    meta: {
      title: '正在登录'
    },
    component: () => import('../views/refueling/adminCallback.vue')
  },
  {
    path: '/refueling/tel',
    meta: {
      title: '信息注册'
    },
    component: () => import('../views/refueling/tel.vue')
  },
  {
    path: '/refueling/self_help_reg',
    meta: {
      title: '场所注册'
    },
    component: () => import('../views/refueling/self_help_reg.vue')
  },
  {
    path: '/refueling/bind',
    meta: {
      title: '绑定岗位'
    },
    component: () => import('../views/refueling/bind.vue')
  },
  {
    path: '/refueling/bind_post',
    meta: {
      title: '密码设置'
    },
    component: () => import('../views/refueling/bind_post.vue')
  },
  {
    path: '/refueling/input_pass',
    meta: {
      title: '输入岗位密码'
    },
    component: () => import('../views/refueling/input_pass.vue')
  },
  {
    path: '/refueling/record_info',
    meta: {
      title: '登记信息'
    },
    component: () => import('../views/refueling/record_info.vue')
  },
  {
    path: '/refueling/facesuccess',
    meta: {
      title: '信息提示'
    },
    component: () => import('../views/refueling/facesuccess.vue')
  },
  {
    path: '/refueling/faceerror',
    meta: {
      title: '信息提示'
    },
    component: () => import('../views/refueling/faceerror.vue')
  },
  {
    path: '/login/mycallback',
    meta: {
      title: '正在登录'
    },
    component: () => import('../views/login/userCallback.vue')
  },
  {
    path: '/login/callback',
    meta: {
      title: '正在登录'
    },
    component: () => import('../views/login/adminCallback.vue')
  },
  {
    path: '/position/list',
    meta: {
      title: '岗位信息'
    },
    component: () => import('../views/position/list.vue')
  },
  {
    path: '/position/add',
    meta: {
      title: '添加岗位'
    },
    component: () => import('../views/position/add.vue')
  },
  {
    path: '/position/detail/:id',
    meta: {
      title: '岗位信息'
    },
    component: () => import('../views/position/detail.vue')
  },
  {
    path: '/position/bind',
    meta: {
      title: '绑定岗位'
    },
    component: () => import('../views/position/bind.vue')
  },
  {
    path: '/position/bindqq',
    meta: {
      title: '绑定岗位请求'
    },
    component: () => import('../views/position/bindqq.vue')
  },
  {
    path: '/position/bind_post',
    meta: {
      title: '密码审批方式设置'
    },
    component: () => import('../views/position/bind_post.vue')
  },
  {
    path: '/position/input_pass',
    meta: {
      title: '输入岗位密码'
    },
    component: () => import('../views/position/input_pass.vue')
  },
  {
    path: '/pharmacy/list',
    meta: {
      title: '购药信息'
    },
    component: () => import('../views/pharmacy/list.vue')
  },
  {
    path: '/pharmacy/my',
    meta: {
      title: '购药我的'
    },
    component: () => import('../views/pharmacy/my.vue')
  },
  {
    path: '/pharmacy/record_info',
    meta: {
      title: '登记信息'
    },
    component: () => import('../views/pharmacy/record_info.vue')
  },
  {
    path: '/pharmacy/list_bak',
    meta: {
      title: 'new购药信息'
    },
    component: () => import('../views/pharmacy/list_bak.vue')
  },
  {
    path: '/pharmacy/list_bak1',
    meta: {
      title: 'new购药信息1'
    },
    component: () => import('../views/pharmacy/list_bak1.vue')
  },
  {
    path: '/assistregister/register_1',
    meta: {
      title: '身份信息'
    },
    component: () => import('../views/assistregister/register_1.vue')
  },
  {
    path: '/assistregister/register_2',
    meta: {
      title: '近照车牌信息'
    },
    component: () => import('../views/assistregister/register_2.vue')
  },
  {
    path: '/assistregister/register_3',
    meta: {
      title: '老年病信息'
    },
    component: () => import('../views/assistregister/register_3.vue')
  },
  {
    path: '/assistregister/submit_success',
    meta: {
      title: '提交信息'
    },
    component: () => import('../views/assistregister/submit_success.vue')
  },
  {
    path: '/assistregister/bind_print',
    meta: {
      title: '绑定打印机'
    },
    component: () => import('../views/assistregister/bind_print.vue')
  },
  {
    path: '/assistregister/bind_print_zc',
    meta: {
      title: '绑定打印机'
    },
    component: () => import('../views/assistregister/bind_print_zc.vue')
  },
  {
    path: '/assistregister/record_info_bak',
    meta: {
      title: '登记信息'
    },
    component: () => import('../views/assistregister/record_info_bak.vue')
  },
  {
    path: '/assistregister/record_info',
    meta: {
      title: '登记信息'
    },
    component: () => import('../views/assistregister/record_info.vue')
  },
  {
    path: '/assistregister/current_code',
    meta: {
      title: '通行码'
    },
    component: () => import('../views/assistregister/current_code.vue')
  },
  {
    path: '/publicregister/register_1',
    meta: {
      title: '身份信息'
    },
    component: () => import('../views/publicregister/register_1.vue')
  },
  {
    path: '/publicregister/submit_success',
    meta: {
      title: '提交信息'
    },
    component: () => import('../views/publicregister/submit_success.vue')
  },
  {
    path: '/publicregister/bind_print',
    meta: {
      title: '绑定打印机'
    },
    component: () => import('../views/publicregister/bind_print.vue')
  },
  {
    path: '/publicregister/bind_print_zc',
    meta: {
      title: '绑定打印机'
    },
    component: () => import('../views/publicregister/bind_print_zc.vue')
  },
  {
    path: '/publicregister/record_info',
    meta: {
      title: '登记信息'
    },
    component: () => import('../views/publicregister/record_info.vue')
  },
  {
    path: '/publicregister/record_info_1',
    meta: {
      title: '登记信息'
    },
    component: () => import('../views/publicregister/record_info_1.vue')
  },
  {
    path: '/medical/prescription_choose',
    meta: {
      title: '选择药品'
    },
    component: () => import('../views/medical/prescription_choose.vue')
  },
  {
    path: '/medical/prescription_confirm',
    meta: {
      title: '确认购药信息'
    },
    component: () => import('../views/medical/prescription_confirm.vue')
  },
  {
    path: '/medical/prescription_pay',
    meta: {
      title: '确认支付'
    },
    component: () => import('../views/medical/prescription_pay.vue')
  },
  {
    path: '/medical/prescription_choose_formal',
    meta: {
      title: '选择药品'
    },
    component: () => import('../views/medical/prescription_choose_formal.vue')
  },
  {
    path: '/medical/prescription_confirm_formal',
    meta: {
      title: '确认购药信息'
    },
    component: () => import('../views/medical/prescription_confirm_formal.vue')
  },
  {
    path: '/medical/prescription_pay_formal',
    meta: {
      title: '确认支付'
    },
    component: () => import('../views/medical/prescription_pay_formal.vue')
  },
  {
    path: '/medical/tel',
    meta: {
      title: '注册'
    },
    component: () => import('../views/medical/tel.vue')
  },
  {
    path: '/medical/self_help_reg',
    meta: {
      title: '场所注册'
    },
    component: () => import('../views/medical/self_help_reg.vue')
  },
  {
    path: '/medical/admin_index',
    meta: {
      title: '药店管理'
    },
    component: () => import('../views/medical/admin_index.vue')
  },
  {
    path: '/medical/my',
    meta: {
      title: '我的信息'
    },
    component: () => import('../views/medical/my.vue')
  },
  {
    path: '/medical/ewm_detail',
    meta: {
      title: '查看二维码'
    },
    component: () => import('../views/medical/ewm_detail.vue')
  },
  {
    path: '/medical/bind_post',
    meta: {
      title: '密码设置'
    },
    component: () => import('../views/medical/bind_post.vue')
  },
  {
    path: '/medical/drug_list',
    meta: {
      title: '药品管理'
    },
    component: () => import('../views/medical/drug_list.vue')
  },
  {
    path: '/medical/drug_add',
    meta: {
      title: '药品添加'
    },
    component: () => import('../views/medical/drug_add.vue')
  },
  {
    path: '/medical/scan_code',
    meta: {
      title: '扫码绑定'
    },
    component: () => import('../views/medical/scan_code.vue')
  },
  {
    path: '/medical/input_pass',
    meta: {
      title: '密码验证'
    },
    component: () => import('../views/medical/input_pass.vue')
  },
  {
    path: '/policestation/barList',
    meta: {
      title: '场所列表'
    },
    component: () => import('../views/policestation/barList.vue')
  },
  {
    path: '/policestation/login',
    meta: {
      title: '登录'
    },
    component: () => import('../views/policestation/login.vue')
  },
  {
    path: '/policestation/login1',
    meta: {
      title: '登录'
    },
    component: () => import('../views/policestation/login1.vue')
  },
  {
    path: '/policestation/roomList',
    meta: {
      title: '房间列表'
    },
    component: () => import('../views/policestation/roomList.vue')
  },
  {
    path: '/policestation/userDetail',
    meta: {
      title: '上机详情'
    },
    component: () => import('../views/policestation/userDetail.vue')
  },
  {
    path: '/policestation/userList',
    meta: {
      title: '上机列表'
    },
    component: () => import('../views/policestation/userList.vue')
  },
  {
    path: '/temptest/list',
    meta: {
      title: '测试信息'
    },
    component: () => import('../views/temptest/list.vue')
  }, 
  {
    path: '/nopermission',
    meta: {
      title: '没有权限'
    },
    component: () => import('../views/noPermisstion.vue')
  },
  {
    path: '/succ',
    meta: {
      title: '成功'
    },
    component: () => import('../views/success.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to, from) => {
  document.title = get(to, 'meta.title');
});

export default router;
