import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { Button, Field, Toast } from 'vant';
import { getOpenId } from './utils';
import 'normalize.css';
import './styles/style.less';
import App from './App.vue';
import router from './router';
import store from './store';
import calendarSwitch from 'vue-calendar-switch'// 同联金梅20200420增加
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'//这里注意具体看使用的版本是否需要引入样式，以及具体位置。
Vue.use(VueAwesomeSwiper, /* { default global options } */)


//axios.defaults.baseURL = 'https://cloud.sliverstar.cn';
axios.defaults.baseURL = 'http://ywfwpt.ywkj.online';
axios.defaults.headers = {
  'Content-Type': 'application/x-www-form-urlencoded'
};
axios.interceptors.request.use(config => {
  config.headers.openid = getOpenId();
  return config;
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

Vue.use(VueAxios, axios);
Vue.use(Button);
Vue.use(Toast);

Vue.config.productionTip = false;

Vue.prototype.$calendarSwitch = calendarSwitch;// 同联金梅20200420增加

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
