export const getOpenId = () => {
  return window.sessionStorage.getItem('openid');
};

export const getUserinfo = () => {
  let userinfo = window.sessionStorage.getItem('userinfo');
  return userinfo ? JSON.parse(userinfo) : {};
};

export const isEmpty = value => {
  if (value === null || value === undefined || value.trim().length === 0) {
    return true;
  }
  return false;
};

export const isTel = value => {
  var reg = /^0?1[0-9]{10}$/;
  return reg.test(value);
};
